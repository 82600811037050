import React from "react";

const Info1 = () => {
  return (
    <>
      <div className="pj-detials-page pt-110 pb-140">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="pj-details-hero-img w-img p-relative">
                <img src="assets/img/project/1.png" alt="" />
                <div className="pj-detials-meta-box white-bg">
                  <h3 className="pj-details-meta-title">CLOSED PROTECTION</h3>
                  <div className="pj-detials-meta-info">
                    <span>
                      <b>Clients:</b>Worldwide
                    </span>
                    <span>
                      <b>Category:</b>
                      <a href="/"> Personal Security</a>
                    </span>
                    <span className="pj-share-icons">
                      <b>Share:</b>
                      <a href="/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="pj-detials-contact pt-85">
                <h3 className="pj-detials-title">A closer look at Closed Protection...</h3>
                <p>
                Close protection, also known as executive or personal protection, is a specialized security service designed to safeguard individuals from potential threats, harm, or unwanted attention. This type of security service is often tailored to high-profile individuals, executives, diplomats, or anyone who may be at risk due to their status or occupation. Unlike general security measures, close protection involves a proactive, personalized approach, where highly trained professionals work closely with clients to anticipate, detect, and respond to threats. This level of protection is essential in today’s world, where risks such as physical attacks, kidnapping, and stalking have become more prevalent, especially for those who are in the public eye or hold influential positions.
                </p>
                <p>
                Proteus, a premier close protection provider, stands out in the industry for its commitment to excellence, client-centered approach, and highly skilled personnel. Proteus ensures its clients receive more than just a standard security service by emphasizing a personalized and discreet approach tailored to each client’s unique needs. The company understands that no two clients have the same security requirements, so its solutions are flexible and adaptable to individual risk profiles and personal preferences. Proteus’s ability to provide customized protection is a major asset, as it allows clients to maintain their lifestyle with minimal disruption while feeling confident that their safety is a top priority.
                </p>
                <p>
                Another reason Proteus excels as a close protection provider is its team of seasoned professionals. The company employs security experts with extensive backgrounds in law enforcement, military, and intelligence fields, ensuring they bring a wealth of experience and practical knowledge to each assignment. Proteus prioritizes rigorous training and continual education for its team, covering threat detection, risk assessment, and emergency response. This commitment to expertise ensures that Proteus’s close protection officers can effectively handle various situations, from high-risk environments to low-profile social settings, making them invaluable in a range of contexts.
                </p>
                <p>
                Furthermore, Proteus utilizes advanced technology and security measures to offer cutting-edge protection. From surveillance and monitoring systems to secure communication tools, Proteus integrates the latest innovations in security technology to enhance client safety. By blending state-of-the-art tools with a highly skilled team, Proteus not only provides physical security but also offers peace of mind. Their comprehensive approach to close protection combines traditional methods with modern advancements, making Proteus a leading choice for individuals seeking reliable, discreet, and thorough protection services.
                </p>
                <div className="pj-detials-pagenation d-flex justify-content-between">
                  <div className="pj-detials-pagenation-left">
                    <span>
                      <a href="/cyber">
                        <svg
                          width="39"
                          height="16"
                          viewBox="0 0 39 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.292892 7.29289C-0.0976295 7.68342 -0.0976295 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM39 7L1 7V9L39 9V7Z"
                            fill="currentColor"
                          />
                        </svg>
                        back
                      </a>
                    </span>
                  </div>
                  <div className="pj-detials-pagenation-right">
                    <span>
                      <a href="/residential">
                        next
                        <svg
                          width="43"
                          height="16"
                          viewBox="0 0 43 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M42.7071 8.70711C43.0976 8.31659 43.0976 7.68342 42.7071 7.2929L36.3431 0.928935C35.9526 0.538411 35.3195 0.538411 34.9289 0.928935C34.5384 1.31946 34.5384 1.95262 34.9289 2.34315L40.5858 8L34.9289 13.6569C34.5384 14.0474 34.5384 14.6805 34.9289 15.0711C35.3195 15.4616 35.9526 15.4616 36.3431 15.0711L42.7071 8.70711ZM-8.74228e-08 9L42 9L42 7L8.74228e-08 7L-8.74228e-08 9Z"
                            fill="currentColor"
                          />
                        </svg>{" "}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info1;

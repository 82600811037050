import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import Info4 from "./Info4.js";

const Event = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <Info4 />
      <Footer />
    </>
  );
};

export default Event;

import React from "react";

const Info7 = () => {
  return (
    <>
      <div className="pj-detials-page pt-110 pb-140">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="pj-details-hero-img w-img p-relative">
                <img src="assets/img/project/7.png" alt="" />
                <div className="pj-detials-meta-box white-bg">
                  <h3 className="pj-details-meta-title">TRAVEL SECURITY</h3>
                  <div className="pj-detials-meta-info">
                    <span>
                      <b>Client:</b>Worldwide
                    </span>
                    <span>
                      <b>Category:</b>
                      <a href="/"> Transportation and Logistics</a>
                    </span>
                    <span className="pj-share-icons">
                      <b>Share:</b>
                      <a href="/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="pj-detials-contact pt-85">
                <h3 className="pj-detials-title">Travel safely...</h3>
                <p>
                Travel and transport security is essential for ensuring the safety and well-being of individuals and goods while they are in transit. Whether it involves the protection of high-profile individuals, corporate executives, or valuable shipments, the risks during travel can be varied and unpredictable. These risks can range from physical threats, such as hijacking or theft, to potential disruptions due to geopolitical instability, natural disasters, or civil unrest. Travel and transport security services are designed to identify, mitigate, and respond to these risks, offering comprehensive protection for people and assets as they move across domestic or international borders.
                </p>
                <p>
                For individuals, travel security often involves protective services such as close protection teams, secure transportation, and route planning. High-profile executives, diplomats, or celebrities often require secure transport arrangements to protect them from potential threats, whether they are traveling by car, plane, or boat. Security personnel are trained to assess travel risks, monitor the environment, and provide real-time responses in the event of an emergency. Travel security also includes monitoring potential risks along the route, such as areas with high crime rates or political instability, and making necessary adjustments to ensure the traveler’s safety. By ensuring that all aspects of the journey are carefully planned and monitored, individuals can travel with peace of mind, knowing that they are protected from potential dangers.
                </p>
                <p>
                Transport security is equally critical for organizations that need to move valuable goods, equipment, or sensitive information. This often involves the protection of cargo during shipment via air, sea, or land. Companies may rely on transport security services to monitor and secure the movement of high-value goods or confidential materials, ensuring that they arrive at their destination without tampering, theft, or loss. This includes securing warehouses, containers, and vehicles used for transportation, as well as ensuring that the personnel involved are trained in handling sensitive or high-risk shipments. Additionally, GPS tracking, secure transport protocols, and real-time monitoring allow businesses to track their shipments and respond quickly if any security breaches occur during transit.
                </p>
                <p>
                Proteus excels in providing comprehensive travel and transport security solutions, ensuring that individuals and valuable shipments are protected throughout their journey. For travelers, Proteus offers personalized services, including secure transportation, route analysis, and close protection teams, all tailored to specific security needs and travel conditions. For organizations, Proteus provides robust solutions for securing cargo and sensitive materials, utilizing technology such as tracking systems and real-time threat monitoring to ensure that shipments reach their destination safely. With its focus on both human expertise and cutting-edge security technologies, Proteus is a trusted partner for individuals and businesses seeking reliable, thorough protection during their travel and transportation needs.
                </p>
                <div className="pj-detials-pagenation d-flex justify-content-between">
                  <div className="pj-detials-pagenation-left">
                    <span>
                      <a href="/asset">
                        <svg
                          width="39"
                          height="16"
                          viewBox="0 0 39 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.292892 7.29289C-0.0976295 7.68342 -0.0976295 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM39 7L1 7V9L39 9V7Z"
                            fill="currentColor"
                          />
                        </svg>
                        back
                      </a>
                    </span>
                  </div>
                  <div className="pj-detials-pagenation-right">
                    <span>
                      <a href="/cyber">
                        next
                        <svg
                          width="43"
                          height="16"
                          viewBox="0 0 43 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M42.7071 8.70711C43.0976 8.31659 43.0976 7.68342 42.7071 7.2929L36.3431 0.928935C35.9526 0.538411 35.3195 0.538411 34.9289 0.928935C34.5384 1.31946 34.5384 1.95262 34.9289 2.34315L40.5858 8L34.9289 13.6569C34.5384 14.0474 34.5384 14.6805 34.9289 15.0711C35.3195 15.4616 35.9526 15.4616 36.3431 15.0711L42.7071 8.70711ZM-8.74228e-08 9L42 9L42 7L8.74228e-08 7L-8.74228e-08 9Z"
                            fill="currentColor"
                          />
                        </svg>{" "}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info7;

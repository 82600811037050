import React, { useState } from "react";
import { Link } from "react-router-dom";
// internal
import menu_data from "./MobileData";

const MobileMenus = () => {
  const [navTitle, setNavTitle] = useState("");

  // openMobileMenu
  const openMobileMenu = (menu) => {
    // Toggle dropdown visibility based on menu title
    setNavTitle((prevNavTitle) => (prevNavTitle === menu ? "" : menu));
  };

  return (
    <nav className="mean-nav">
      <ul>
        {menu_data.map((menu, i) => (
          <React.Fragment key={i}>
            {menu.hasDropdown ? (
              <li className="has-dropdown">
                <Link to={menu.link} style={{ color: 'black' }}>
                  {menu.title}
                </Link>
                <ul
                  className="submenu"
                  style={{
                    display: navTitle === menu.title ? "block" : "none",
                  }}
                >
                  {menu.submenus.map((sub, i) => (
                    <li key={i}>
                      <Link to={sub.link} style={{ color: 'black' }}>
                        {sub.title}
                      </Link>
                    </li>
                  ))}
                </ul>
                <button
                  className={`mean-expand ${
                    navTitle === menu.title ? "mean-clicked" : ""
                  }`}
                  onClick={() => openMobileMenu(menu.title)}
                  style={{
                    fontSize: "18px",
                    background: "none",
                    border: "none",
                    padding: 0,
                  }}
                >
                  <i className="fal fa-plus"></i>
                </button>
              </li>
            ) : (
              <li>
                <Link to={menu.link} style={{ color: 'black' }}>
                  {menu.title}
                </Link>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </nav>
  );
};

export default MobileMenus;

import React from "react";

const ServiceDetailsAbout = () => {
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">PRIVACY POLICY</h3><br></br>
                <h3 className="tp-sv__title">Part 1: General Privacy Practices</h3>
                
                <p>
                At Proteus Security, we value and respect your privacy. This Privacy Policy outlines how we collect, use, and protect your personal information when you visit our website or use our services. By using our website or engaging with our services, you agree to the practices outlined in this policy.
                <br></br>1. Information We Collect

We may collect the following types of personal information when you interact with our website:

    Personal Identifiable Information (PII): This includes your name, email address, phone number, and any other information you provide when you request our services, fill out forms, or contact us.
    Non-personally Identifiable Information (Non-PII): We may collect information such as your IP address, browser type, device information, and website usage data to improve the user experience.

    <br></br>2. How We Use Your Information

Proteus Security uses the personal information you provide in the following ways:
<br></br>
    To provide our services: We use your contact details to respond to inquiries, provide consultations, and deliver requested security services.
    To improve our website: Non-personally identifiable data helps us analyze website traffic, troubleshoot issues, and enhance the user experience.
    To communicate with you: We may use your email address or phone number to send updates, promotions, or newsletters about our services. You can opt-out of these communications at any time.
    To comply with legal obligations: We may need to use and disclose personal information to comply with legal obligations or enforce our legal rights.

    <br></br>3. How We Protect Your Information

Proteus Security is committed to maintaining the security of your personal information. We implement appropriate security measures to protect your data from unauthorized access, alteration, or destruction. However, no data transmission or storage method is completely secure, and we cannot guarantee absolute security.
<br></br>4. Sharing Your Information

We do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following circumstances:

    Service providers: We may share your information with trusted third-party service providers who help us operate our business and deliver services, such as website hosting and marketing services. These providers are obligated to protect your data and use it only for the purposes we specify.
    Legal obligations: We may share your information when required by law or if we believe such action is necessary to comply with legal processes, protect our rights, or ensure the safety of our users.

                </p>

                <h4 className="tp-sv__subtitle">
                Privacy Policy - Part 2: Cookies, Tracking, and Data Collection
                </h4>
                <p>
                <br></br>     5. Use of Cookies

Proteus Security uses cookies to enhance your browsing experience. Cookies are small files that are placed on your device to store preferences, improve functionality, and track website usage. Cookies help us personalize content and ads, provide social media features, and analyze our website traffic.

You can control the use of cookies through your browser settings. You may choose to block or delete cookies, but please note that this may affect the functionality of our website.
<br></br>6. Third-Party Tracking and Advertising

We may use third-party advertising services (e.g., Google Analytics, Facebook Ads) to help us deliver targeted ads and analyze how users interact with our website. These third-party services may use cookies and other tracking technologies to collect information about your online activities. The data collected is used to deliver personalized ads and to measure the effectiveness of our marketing campaigns.

For more information on how these services collect and use your data, you can review their privacy policies:
<br></br>
    Google Analytics Privacy Policy
    Facebook Privacy Policy
    <br></br>7. Your Rights<br></br>

You have the right to access, correct, or delete your personal data. If you wish to update or remove your information, please contact us directly at the contact details provided below. Additionally, you may have the right to object to or restrict certain processing of your data, such as receiving marketing communications.
<br></br>8. Data Retention

We retain personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, or as required by law. Once we no longer need your data for these purposes, we will securely delete it.
<br></br>9. International Data Transfers

Proteus Security operates internationally, and your personal data may be transferred to, stored, and processed outside of your country of residence. By using our website, you consent to the transfer of your information to countries outside your country, where data protection laws may differ.
<br></br>10. Changes to This Privacy Policy

Proteus Security reserves the right to update or modify this Privacy Policy at any time. Any changes will be posted on this page, and the updated policy will be effective as of the date of posting. We encourage you to review this Privacy Policy periodically to stay informed about how we protect your data.

                </p>
                <p>
                11. Contact Us
                <br></br>
If you have any questions or concerns about this Privacy Policy or our data practices, please contact us:
<br></br>
    Email: proteus@post.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default ServiceDetailsAbout;

import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import Accordion from "./Accordion";

const FAQ = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <Accordion />
      <Footer />
    </>
  );
};

export default FAQ;

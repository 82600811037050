import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import Info2  from "./Info2";

const Residential = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <Info2 />
      <Footer />
    </>
  );
};

export default Residential;

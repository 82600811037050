import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import Info1 from "./Info1";

const Protection = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <Info1 />
      <Footer />
    </>
  );
};

export default Protection;

const menu_data = [
  {
    id: 1,
    title: 'Home',
    link: '/',
  },
  {
    id: 2,
    title: 'About',
    link: '/about',
  },
  {
    id: 3,
    title: 'Services',
    link: '/protection',
  },
  {
    id: 3,
    title: 'Contact',
    link: '/contact',
  },
]

export default menu_data;

import React from "react";
import { Link } from "react-router-dom";

const TopCategoryHomePageTwo = () => {
  return (
    <>
      <div className="tp-cta-area-2 grey-bg-4">
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-lg-12 col-xl-4">
              <div className="tp-cta-2-left black-bg pt-135 pr-30 pl-90 pb-135">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-20">
                  Why Choose Proteus Security?
                  </span>
                  <h2 className="tp-section-title text-white tp-title-size">
                  Unmatched Protection for Your Peace of Mind
                  </h2>
                  <p>
                  Proteus Security for your close protection needs ensures a level of safety and professionalism that is unmatched in the industry. With a team of highly trained and experienced security professionals, Proteus Security offers tailored solutions designed to meet the unique needs of each client. Whether you're an executive, celebrity, or high-net-worth individual, their services include comprehensive risk assessments, secure transportation, residential security, and personal protection, providing peace of mind wherever you go. The company's commitment to discreet yet effective security means you can focus on your day-to-day activities without worry, knowing that your safety is their top priority.
                  </p>
                  <p className="pt-10">
                  We stands out not only for its expertise but also for its proactive approach to security. By analyzing potential threats and vulnerabilities, they create customized protection plans that address every aspect of your safety—both seen and unseen. From event security to family protection, their holistic approach ensures that every detail is covered, so you never have to compromise on your security. With cutting-edge technology, rapid response capabilities, and a reputation for excellence, Proteus Security offers a level of protection that is second to none, allowing you to move with confidence, knowing you're in capable hands.
                  </p>
                  <div className="mt-50">
                    <Link to="/protection" className="tp-btn-white">
                      View More
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="tp-cta-img w-img">
                <img src="assets/img/cta/cta.png" alt="" />
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="tp-cta-2-right white-bg pt-135 pr-30 pl-75 pb-135">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle mb-20">
                  Your Safety, Our Priority
                  </span>
                  <h2 className="tp-section-title tp-title-size">
                  Comprehensive Security Solutions Tailored to Your Needs
                  </h2>
                  <p>
                  At Proteus Security, we offer a wide range of specialized services to ensure the safety and well-being of our clients. Our services include personal protection with experienced bodyguards, secure transportation with armored vehicles, and risk assessments to identify potential threats. We also provide event security, ensuring the safety of high-profile individuals during public appearances, as well as residential and perimeter security for added peace of mind at home.
                  </p>
                  <p className="pt-10">
                  Additionally, our family protection services extend to safeguarding loved ones, and our emergency response and evacuation planning ensure rapid action in critical situations. With our comprehensive approach, we deliver tailored security solutions designed to meet the unique needs of every client, ensuring your safety in all aspects of your personal and professional life.
                  </p>
                  <div className="mt-50">
                    <Link to="/event" className="tp-btn-border">
                      View More
                      <span>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                        <svg
                          width="22"
                          height="8"
                          viewBox="0 0 22 8"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopCategoryHomePageTwo;

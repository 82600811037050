import React from "react";

const Info2 = () => {
  return (
    <>
      <div className="pj-detials-page pt-110 pb-140">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="pj-details-hero-img w-img p-relative">
                <img src="assets/img/project/2.png" alt="" />
                <div className="pj-detials-meta-box white-bg">
                  <h3 className="pj-details-meta-title">RESIDENTIAL</h3>
                  <div className="pj-detials-meta-info">
                    <span>
                      <b>Client:</b>UK based
                    </span>

                    <span>
                      <b>Category:</b>
                      <a href="/"> Home Protection</a>
                    </span>
                    <span className="pj-share-icons">
                      <b>Share:</b>
                      <a href="/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="pj-detials-contact pt-85">
                <h3 className="pj-detials-title">How we can protect your home...</h3>
                <p>
                Residential security is a specialized field of security focused on protecting homes, estates, and residential complexes from potential threats, intrusions, and emergencies. In today’s world, where risks to personal property and family safety are ever-present, residential security has become a crucial service for many individuals, especially those with high-profile statuses, substantial assets, or residences in secluded or high-crime areas. Unlike conventional security systems, residential security offers a layered approach, involving both technological and human elements to ensure round-the-clock protection. This not only helps to deter intruders but also provides a rapid response in case of a breach or other emergencies.
                </p>
                <p>
                A good residential security service provides a customized plan that takes into account the specific needs and vulnerabilities of each residence. For instance, a large estate may require perimeter security, including surveillance cameras, motion detectors, and secured access points, while an urban property might focus more on entry-point security and immediate response capabilities. Companies like Proteus excel in tailoring their residential security solutions to meet the individual demands of each residence, working closely with clients to identify potential weak spots and implementing practical measures to counteract them. This personalized approach ensures that clients can live with peace of mind, knowing their home and family are protected.
                </p>
                <p>
                Proteus sets itself apart in residential security by utilizing highly trained personnel who understand the importance of discretion, respect, and vigilance. Unlike general security guards, Proteus’s residential security officers are selected for their ability to blend seamlessly into the home environment while maintaining a strong protective presence. Their training includes not only security protocols but also a focus on customer service, communication, and adaptability to family routines. This ensures that residents feel both secure and comfortable, as the security team works effectively to protect without intruding on their day-to-day lives.
                </p>
                <p>
                Additionally, Proteus leverages advanced security technology to enhance its residential protection services. From state-of-the-art surveillance systems and alarm integrations to secure access control and remote monitoring, Proteus employs a full spectrum of technological solutions to ensure robust security coverage. By combining human expertise with cutting-edge tools, Proteus provides a comprehensive residential security solution that offers clients true peace of mind. This integrated approach, alongside Proteus’s commitment to confidentiality and professionalism, makes the company an ideal choice for those seeking trustworthy and thorough residential security.
                </p>
                <div className="pj-detials-pagenation d-flex justify-content-between">
                  <div className="pj-detials-pagenation-left">
                    <span>
                      <a href="/protection">
                        <svg
                          width="39"
                          height="16"
                          viewBox="0 0 39 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.292892 7.29289C-0.0976295 7.68342 -0.0976295 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM39 7L1 7V9L39 9V7Z"
                            fill="currentColor"
                          />
                        </svg>
                        back
                      </a>
                    </span>
                  </div>
                  <div className="pj-detials-pagenation-right">
                    <span>
                      <a href="/corporate">
                        next
                        <svg
                          width="43"
                          height="16"
                          viewBox="0 0 43 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M42.7071 8.70711C43.0976 8.31659 43.0976 7.68342 42.7071 7.2929L36.3431 0.928935C35.9526 0.538411 35.3195 0.538411 34.9289 0.928935C34.5384 1.31946 34.5384 1.95262 34.9289 2.34315L40.5858 8L34.9289 13.6569C34.5384 14.0474 34.5384 14.6805 34.9289 15.0711C35.3195 15.4616 35.9526 15.4616 36.3431 15.0711L42.7071 8.70711ZM-8.74228e-08 9L42 9L42 7L8.74228e-08 7L-8.74228e-08 9Z"
                            fill="currentColor"
                          />
                        </svg>{" "}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info2;

import React from "react";

const Info3 = () => {
  return (
    <>
      <div className="pj-detials-page pt-110 pb-140">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="pj-details-hero-img w-img p-relative">
                <img src="assets/img/project/3.png" alt="" />
                <div className="pj-detials-meta-box white-bg">
                  <h3 className="pj-details-meta-title">CORPORATE</h3>
                  <div className="pj-detials-meta-info">
                    <span>
                      <b>Client:</b>Worldwide
                    </span>
                    <span>
                      <b>Category:</b>
                      <a href="/"> Business Security</a>
                    </span>
                    <span className="pj-share-icons">
                      <b>Share:</b>
                      <a href="/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="pj-detials-contact pt-85">
                <h3 className="pj-detials-title">Your business demands great security...</h3>
                <p>
                Corporate security is an essential component for businesses aiming to protect their assets, employees, and information from a wide range of potential threats. In today’s complex landscape, organizations face risks that include physical breaches, cyber attacks, intellectual property theft, and insider threats. Corporate security goes beyond physical protection and involves comprehensive strategies to mitigate risks across the entire enterprise. This ensures that businesses can operate smoothly and securely, protecting not only tangible assets but also sensitive information, trade secrets, and the well-being of employees. Effective corporate security requires a tailored approach, as every organization has unique needs and vulnerabilities based on its industry, size, and operational structure.
                </p>
                <p>
                Proteus offers a top-tier corporate security service, standing out as a reliable provider thanks to its specialized approach and industry expertise. By conducting detailed risk assessments and working closely with each client, Proteus develops security plans that address specific corporate needs, from protecting physical infrastructure to implementing robust cybersecurity protocols. Proteus understands that threats to corporations are dynamic, constantly evolving in sophistication and scope. Therefore, the company emphasizes adaptability in its security solutions, ensuring they remain effective in the face of emerging risks and allowing clients to focus on their core business functions with minimal disruption.
                </p>
                <p>
                One of Proteus’s key strengths in corporate security lies in its experienced and highly trained personnel, who are equipped to handle both routine and crisis situations. Proteus’s team members are often former law enforcement, military, or intelligence professionals with backgrounds that prepare them for complex corporate environments. Their training includes not only threat detection and response but also customer-focused skills, ensuring that they can interact professionally and effectively with employees, clients, and visitors. This comprehensive training ensures that Proteus personnel can seamlessly integrate into corporate settings, providing a protective presence while respecting the company’s culture and maintaining a low profile when necessary.
                </p>
                <p>
                In addition to skilled personnel, Proteus leverages cutting-edge security technology to provide an all-encompassing solution for corporate protection. This includes surveillance systems, advanced access control measures, threat monitoring, and cybersecurity tools to guard against digital threats. Proteus recognizes that modern corporate security must address both physical and cyber realms, as breaches in either area can have devastating effects on a business. By combining human expertise with technology, Proteus is able to offer proactive, 24/7 protection that covers every facet of corporate security. This holistic approach, along with Proteus’s reputation for reliability and discretion, makes the company an excellent choice for businesses seeking comprehensive protection for their operations and assets.
                </p>
                <div className="pj-detials-pagenation d-flex justify-content-between">
                  <div className="pj-detials-pagenation-left">
                    <span>
                      <a href="/residential">
                        <svg
                          width="39"
                          height="16"
                          viewBox="0 0 39 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.292892 7.29289C-0.0976295 7.68342 -0.0976295 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM39 7L1 7V9L39 9V7Z"
                            fill="currentColor"
                          />
                        </svg>
                        back
                      </a>
                    </span>
                  </div>
                  <div className="pj-detials-pagenation-right">
                    <span>
                      <a href="/event">
                        next
                        <svg
                          width="43"
                          height="16"
                          viewBox="0 0 43 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M42.7071 8.70711C43.0976 8.31659 43.0976 7.68342 42.7071 7.2929L36.3431 0.928935C35.9526 0.538411 35.3195 0.538411 34.9289 0.928935C34.5384 1.31946 34.5384 1.95262 34.9289 2.34315L40.5858 8L34.9289 13.6569C34.5384 14.0474 34.5384 14.6805 34.9289 15.0711C35.3195 15.4616 35.9526 15.4616 36.3431 15.0711L42.7071 8.70711ZM-8.74228e-08 9L42 9L42 7L8.74228e-08 7L-8.74228e-08 9Z"
                            fill="currentColor"
                          />
                        </svg>{" "}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info3;

import React from "react";
import Footer from "../common/Footer/Footer";
import Menu from "../common/Menu/Menu";
import BlogHomeTwo from "./BlogHomeTwo";
import SliderHomeTwo from "./SliderHomeTwo";
import TopCategoryHomePageTwo from "./TopCategoryHomePageTwo";
import TopCtaAreaTwo from "./TopCtaAreaTwo";

const HomeTwo = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <SliderHomeTwo />
      <TopCtaAreaTwo />
      <TopCategoryHomePageTwo />
      <BlogHomeTwo />
      <Footer />
    </>
  );
};

export default HomeTwo;

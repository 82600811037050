import React from "react";

const Info6 = () => {
  return (
    <>
      <div className="pj-detials-page pt-110 pb-140">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="pj-details-hero-img w-img p-relative">
                <img src="assets/img/project/6.png" alt="" />
                <div className="pj-detials-meta-box white-bg">
                  <h3 className="pj-details-meta-title">ASSET PROTECTION</h3>
                  <div className="pj-detials-meta-info">
                    <span>
                      <b>Client:</b>Worldwide
                    </span>
                    <span>
                      <b>Category:</b>
                      <a href="/">Personal and Assets</a>
                    </span>
                    <span className="pj-share-icons">
                      <b>Share:</b>
                      <a href="/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="pj-detials-contact pt-85">
                <h3 className="pj-detials-title">Do my assets need security?</h3>
                <p>
                Asset security is a critical aspect of safeguarding valuable resources, whether physical, intellectual, or digital. Protecting assets involves implementing strategies and measures that prevent theft, damage, or unauthorized access. These assets can range from tangible items such as equipment, cash, or inventory to intangible assets like intellectual property, data, or proprietary information. For businesses, securing assets is vital to maintaining operational integrity, ensuring financial stability, and protecting the organization’s reputation. A comprehensive asset security plan addresses both physical and cyber threats, ensuring that all forms of assets are adequately protected from potential risks.
                </p>
                <p>
                To protect physical assets, businesses often employ a combination of security measures such as surveillance systems, access control, alarms, and physical barriers like locks or gates. These measures help to deter theft, monitor activity, and quickly detect any potential breaches. For instance, warehouses, manufacturing facilities, or corporate offices often rely on advanced video surveillance systems and access control technologies to track who enters and exits premises and to monitor high-value areas. For digital assets, cybersecurity practices such as encryption, firewalls, and secure authentication protocols are implemented to prevent unauthorized access to sensitive data, trade secrets, or proprietary software. By securing both physical and digital assets, organizations can safeguard their most valuable resources.
                </p>
                <p>
                In addition to protecting physical and digital assets, securing intellectual property (IP) is also a critical component of asset security. Intellectual property, including patents, trademarks, copyrights, and proprietary processes, represents a company’s competitive advantage and long-term value. Ensuring IP security involves implementing strict access controls, confidentiality agreements, and cybersecurity measures to protect sensitive documents and designs from theft or unauthorized distribution. It is also important for companies to monitor and enforce intellectual property rights to prevent infringement and maintain a secure business environment where proprietary innovations can flourish without fear of exploitation.
                </p>
                <p>
                Proteus excels in asset security by offering tailored solutions that address both the physical and digital security needs of organizations. The company understands the intricacies of asset protection across different industries and provides specialized services to help clients safeguard their valuable resources. Whether it’s protecting physical premises, securing digital infrastructure, or managing intellectual property risks, Proteus’s team of experts brings extensive experience and cutting-edge technologies to the table. Their proactive approach to asset security ensures that businesses can focus on their core operations with confidence, knowing that their assets are thoroughly protected from a range of potential threats.
                </p>
                <div className="pj-detials-pagenation d-flex justify-content-between">
                  <div className="pj-detials-pagenation-left">
                    <span>
                      <a href="/risk">
                        <svg
                          width="39"
                          height="16"
                          viewBox="0 0 39 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.292892 7.29289C-0.0976295 7.68342 -0.0976295 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM39 7L1 7V9L39 9V7Z"
                            fill="currentColor"
                          />
                        </svg>
                        back
                      </a>
                    </span>
                  </div>
                  <div className="pj-detials-pagenation-right">
                    <span>
                      <a href="/travel">
                        next
                        <svg
                          width="43"
                          height="16"
                          viewBox="0 0 43 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M42.7071 8.70711C43.0976 8.31659 43.0976 7.68342 42.7071 7.2929L36.3431 0.928935C35.9526 0.538411 35.3195 0.538411 34.9289 0.928935C34.5384 1.31946 34.5384 1.95262 34.9289 2.34315L40.5858 8L34.9289 13.6569C34.5384 14.0474 34.5384 14.6805 34.9289 15.0711C35.3195 15.4616 35.9526 15.4616 36.3431 15.0711L42.7071 8.70711ZM-8.74228e-08 9L42 9L42 7L8.74228e-08 7L-8.74228e-08 9Z"
                            fill="currentColor"
                          />
                        </svg>{" "}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info6;

import React from "react";
import Footer from "../common/Footer/Footer";
import Menu from "../common/Menu/Menu";
import ServiceDetailsAbout2 from "../Pages/ServiceDetails/ServiceDetailsAbout2";


const Privacy = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <ServiceDetailsAbout2 />
      <Footer />
    </>
  );
};

export default Privacy;
import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import FullScreenButton from "../../common/FullScreenBtn";
import ServiceDetailsAbout from "../ServiceDetails/ServiceDetailsAbout";

const About = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <ServiceDetailsAbout />
      <FullScreenButton />
      <Footer />
    </>
  );
};

export default About;

import React from "react";

const ServiceDetailsAbout = () => {
  return (
    <>
      <div className="tp-service-page pt-140 pb-140">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="tp-sv__content">
                <h3 className="tp-sv__title">Who are Proteus Security?</h3>
                <p>
At Proteus Security, we are dedicated to delivering unparalleled protection and peace of mind for our clients. With years of expertise in the security industry, our team of skilled professionals is equipped to handle a broad spectrum of protective services tailored to each client’s unique needs. We pride ourselves on our meticulous attention to detail, advanced technology, and commitment to discretion, ensuring that our clients, their assets, and their reputations remain secure at all times. Whether through personal protection, risk assessment, or event security, our mission is to provide unwavering support and confidence to everyone we serve.
                </p>
                <p>
                Our approach to security is comprehensive and proactive. We work closely with clients to understand potential vulnerabilities and design customized security solutions that address them effectively. From executive protection to corporate safeguarding, Proteus Security is known for reliability and excellence in the field. Our clients trust us because we prioritize their safety and privacy, and we consistently deliver responsive, effective protection in every environment. At Proteus Security, we stand as a steadfast partner, dedicated to maintaining the safety and security of those who entrust us with their protection.
                </p>
                <div className="row pb-30">
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-1.png" alt="" />
              </div>
            </div>
            <div className="col-lg-6 mb-30">
              <div className="tp-sv-inner-img w-img">
                <img src="/assets/img/service/service-blog-2.png" alt="" />
              </div>
            </div>
          </div>
                <h4 className="tp-sv__subtitle">
                  What can Proteus Security offer?
                </h4>


                <p>
Proteus Security offers a range of specialized services designed to meet the diverse needs of our clients. Our close protection and executive security services provide personal protection to high-profile individuals, ensuring their safety through expert risk assessment, secure travel arrangements, and vigilant on-site presence. Our team members are highly trained to assess threats in real-time, employing a blend of discretion and strategic intervention to ensure our clients can go about their day-to-day activities with confidence and ease. Additionally, we offer residential security solutions, safeguarding clients' homes with advanced monitoring systems, skilled personnel, and 24/7 support, ensuring a comprehensive layer of protection at all times.
                </p>
                <p>
                Our corporate security services extend from safeguarding physical assets to protecting digital information in an increasingly complex landscape. Proteus Security provides event security, including crowd control and access management, tailored to protect both the venue and its attendees. For businesses, we deliver customized risk management assessments, identify potential security gaps, and offer consulting services that help reinforce your company’s security posture. With a team equipped to handle both everyday and high-risk scenarios, Proteus Security ensures that our clients' interests, properties, and people are protected at every level. Our extensive suite of services is designed to adapt to various security needs, making us a reliable partner in comprehensive, cutting-edge protection.   </p>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default ServiceDetailsAbout;

import React from "react";

const Info4 = () => {
  return (
    <>
      <div className="pj-detials-page pt-110 pb-140">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="pj-details-hero-img w-img p-relative">
                <img src="assets/img/project/4.png" alt="" />
                <div className="pj-detials-meta-box white-bg">
                  <h3 className="pj-details-meta-title">EVENT SECURITY</h3>
                  <div className="pj-detials-meta-info">
                    <span>
                      <b>Client:</b>Worldwide
                    </span>
                    <span>
                      <b>Category:</b>
                      <a href="/"> Entertainment</a>
                    </span>
                    <span className="pj-share-icons">
                      <b>Share:</b>
                      <a href="/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a href="/">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="pj-detials-contact pt-85">
                <h3 className="pj-detials-title">Secure your event, no matter the size...</h3>
                <p>
                Event security is crucial to ensuring the safety and smooth operation of gatherings, whether they are large-scale festivals, corporate conferences, or small private parties. Events, regardless of their size, can attract various types of security threats, including unauthorized access, theft, crowd disturbances, and, in some cases, even targeted attacks. Effective event security involves planning, monitoring, and rapid response strategies to address potential risks while allowing attendees to enjoy the event in a secure environment. With the presence of multiple entry points, large crowds, and potentially high-profile guests, event security requires a specialized, adaptable approach that can address the unique dynamics of each event.
                </p>
                <p>
                Proteus provides exceptional event security services that cater to both large and small gatherings, offering customized security plans that fit the scale, venue, and type of event. For larger events, such as music festivals, sports events, or high-profile corporate galas, Proteus deploys a comprehensive approach involving crowd management, perimeter security, surveillance, and rapid response teams to ensure everything runs smoothly and safely. In smaller venues, like private parties, art galleries, or executive meetings, Proteus can offer a more discreet presence that blends in with the atmosphere while still maintaining a vigilant eye on potential risks. This adaptability makes Proteus an ideal choice for event organizers who need flexible, top-tier security.
                </p>
                <p>
                Proteus’s event security team consists of experienced professionals trained in crowd control, risk assessment, and emergency response, as well as customer service. This ensures that, in addition to safeguarding the event, Proteus personnel can interact politely and professionally with guests, enhancing the event’s atmosphere rather than detracting from it. Proteus understands that a successful event is about more than just security; it’s about creating a positive experience for everyone involved. By employing personnel skilled in both protection and guest relations, Proteus provides an event security service that is not only highly effective but also enhances the guest experience, maintaining a sense of calm and order throughout the event.
                </p>
                <p>
                In addition to skilled personnel, Proteus employs advanced technology to support its event security efforts, from mobile surveillance systems to crowd analytics tools that help monitor attendance and identify potential risks before they escalate. Proteus also uses secure communication systems that allow their team to coordinate swiftly and respond to issues as they arise. By combining human expertise with technological tools, Proteus ensures a rapid, effective response to any situation, whether it’s managing crowd surges at a stadium event or ensuring controlled access at an exclusive private gathering. This blend of people and technology makes Proteus an ideal partner for event organizers who want reliable, comprehensive security for events of any size, ensuring attendees, staff, and organizers feel safe and supported.
                </p>
                <div className="pj-detials-pagenation d-flex justify-content-between">
                  <div className="pj-detials-pagenation-left">
                    <span>
                      <a href="/corporate">
                        <svg
                          width="39"
                          height="16"
                          viewBox="0 0 39 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.292892 7.29289C-0.0976295 7.68342 -0.0976295 8.31658 0.292892 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41422 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292892 7.29289ZM39 7L1 7V9L39 9V7Z"
                            fill="currentColor"
                          />
                        </svg>
                        back
                      </a>
                    </span>
                  </div>
                  <div className="pj-detials-pagenation-right">
                    <span>
                      <a href="/risk">
                        next
                        <svg
                          width="43"
                          height="16"
                          viewBox="0 0 43 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M42.7071 8.70711C43.0976 8.31659 43.0976 7.68342 42.7071 7.2929L36.3431 0.928935C35.9526 0.538411 35.3195 0.538411 34.9289 0.928935C34.5384 1.31946 34.5384 1.95262 34.9289 2.34315L40.5858 8L34.9289 13.6569C34.5384 14.0474 34.5384 14.6805 34.9289 15.0711C35.3195 15.4616 35.9526 15.4616 36.3431 15.0711L42.7071 8.70711ZM-8.74228e-08 9L42 9L42 7L8.74228e-08 7L-8.74228e-08 9Z"
                            fill="currentColor"
                          />
                        </svg>{" "}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Info4;

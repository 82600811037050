import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import Info5 from "./Info5.js";

const Risk = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <Info5 />
      <Footer />
    </>
  );
};

export default Risk;

import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import Info8 from "./Info8.js";

const Cyber = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <Info8 />
      <Footer />
    </>
  );
};

export default Cyber;

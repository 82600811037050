import React from "react";
import Footer from "../../common/Footer/Footer";
import Menu from "../../common/Menu/Menu";
import Info7 from "./Info7.js";

const Travel = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <Info7 />
      <Footer />
    </>
  );
};

export default Travel;

import React from "react";
import { Link } from "react-router-dom";
import "./TopCtaAreaTwo.css"; // Ensure this CSS file is linked

const TopCtaAreaTwo = () => {
  return (
    <>
      {/* <!-- cta area start --> */}
      <div className="tp-cta-area tp-cta-area-page-two pl-110 pr-200">
        <div className="container-fluid">
          <div className="tp-cta-wrapper pt-95 pb-105">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="tp-section-wrapper">
                  <span className="tp-section-subtitle tp-subtitle-before mb-25">
                    Your Security, Our Mission.
                  </span>
                  <h2 className="tp-section-title tp-section-title-large">
                    Protect What Matters Most.
                  </h2>
                </div>
              </div>
              <div className="col-lg-4 button-container">
                <Link to="/contact">
                  <div className="tp-theme-btn diamond-btn">
                    <div className="tp-btn-inner diamond-btn-content">
                      <p className="mb-0">Discover</p>
                      <b>Get in Touch</b>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- cta area end --> */}
    </>
  );
};

export default TopCtaAreaTwo;
